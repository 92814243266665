import store from '@/state/store';
import main from '../views/layouts/main';
import tracking from '../views/layouts/tracking';



export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            offline: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next('/');
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/login/oauth/authorize',
        name: 'login-oauth',
        component: () => import('../views/pages/account/login'),
    },
    {
        path: '/token',
        name: 'token',
        component: () => import('../views/pages/auth/token'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        //begin impersonation by passing userId and userName 
        path: '/impersonate/:id/:name',
        name: 'impersonate',
        component: () => import('../views/pages/account/impersonate'),
        meta: {
            offline: true,
            authRequired: false,
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next('/profile');
                } else {
                    // Continue to the forgot password page
                    next();
                }
            },
        },
    },
    {
        path: '/update-password',
        name: 'update-password',
        component: () => import('../views/pages/account/update-password'),
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            offline: true,
        },
        component: () => import('../views/pages/account/logout'),
    },
    {
        //ends impersonation by mimicking the logout service
        path: '/end-impersonation',
        name: 'end-impersonation',
        meta: {
            offline: true,
        },
        component: () => import('../views/pages/account/impersonate-end'),
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => import('../views/pages/account/reset-password'),
    },
    {
        path: '/verify/:token',
        name: 'verify',
        component: () => import('../views/pages/account/verify'),
    },

    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
        },
        component: () => import('../views/pages/agents/agent-dashboard'),
    },
    {
        path: '/profile',
        name: 'profile',
        meta: { layout: main },
        component: () => import('../views/pages/account/profile'),
    },
    {
        path: '/profile/edit',
        name: 'edit-profile',
        meta: { layout: main },
        component: () => import('../views/pages/account/edit-profile'),
    },
    {
        path: '/profile/change-password',
        name: 'change-password',
        meta: { layout: main },
        component: () => import('../views/pages/account/change-password'),
    },
    {
        path: '/clients/ussd/clients',
        name: 'ussdLogs',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'CLIENTS'],
        },
        component: () => import('../views/pages/clients/USSD/clients'),
    },
    {
        path: '/clients/ussd/dashboard',
        name: 'ussdDashboard',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'CLIENTS'],
        },
        component: () => import('../views/pages/clients/USSD/dashboard'),
    },
    {
        path: '/clients/ussd/sessions',
        name: 'ussdSessions',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'CLIENTS'],
        },
        component: () => import('../views/pages/clients/USSD/sessions'),
    },
    {
        path: '/clients/payments/',
        name: 'ussdPayments',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'CLIENTS'],
        },
        component: () => import('../views/pages/clients/payments/list.vue'),
    },
    {
        path: '/clients/payments/create',
        name: 'payments-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'CLIENTS'],
        },
        component: () => import('../views/pages/clients/payments/create'),
    },
    {
        path: '/clients/payments/edit',
        name: 'payments-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'CLIENTS'],
        },
        component: () => import('../views/pages/clients/payments/edit'),
    },
    {
        path: '/complaint/view',
        name: 'complaints-view',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'Complaints'],
        },
        component: () => import('../views/pages/complaints/complaint-form'),
    },
    {
        path: '/complaint/edit/',
        name: 'complaints-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'Complaints'],
        },
        component: () => import('../views/pages/complaints/edit'),
    },
    {
        path: '/complaints/',
        name: 'complaints',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'Complaints'],
        },
        component: () => import('../views/pages/complaints/index'),
    },
    {
        path: '/clients/grid',
        name: 'clientsgrid',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['CLIENTS'],
        },
        component: () => import('../views/pages/clients/grid'),
    },
    {
        path: '/clients/list',
        name: 'clientslist',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['CLIENTS'],
        },
        component: () => import('../views/pages/clients/list'),
    },
    {
        path: '/clients/edit/:id',
        name: 'client-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['CLIENTS/EDIT'],
        },
        component: () => import('../views/pages/clients/edit'),
    },
    {
        path: '/agents/landingpage',
        name: 'AngentsLanding',
        meta: {
            authRequired: true,
            layout: main,
            offline: true,
            allowedPermissions: ['SALES/AGENTS/MYWORK'],
        },
        component: () => import('../views/pages/agents/landing-page'),
    },
    {
        path: '/clients/groups/details/:id',
        name: 'clientDetails',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['CLIENTS'],
        },
        component: () => import('../views/pages/clients/groups/details'),
    },
    {
        path: '/clients/create',
        name: 'clientscreate',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['CLIENTS/CREATE'],
        },
        component: () => import('../views/pages/clients/create'),
    },

    {
        path: '/clients/groups/create',
        name: 'clients-groups-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['CLIENTS/GROUPS/CREATE'],
        },
        component: () => import('../views/pages/clients/groups/create'),
    },
    {
        path: '/clients/groups/list',
        name: 'clients-groups-list',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['CLIENTS/GROUPS'],
        },
        component: () => import('../views/pages/clients/groups/index'),
    },
    {
        path: '/clients/groups/edit/:id',
        name: 'clients-groups-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['CLIENTS/GROUPS/EDIT'],
        },
        component: () => import('../views/pages/clients/groups/edit'),
    },
    {
        path: '/clients/phone-book',
        name: 'clients-phone-book',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['CLIENTS/PHONEBOOK'],
        },
        component: () => import('../views/pages/clients/phone-book'),
    },
    {
        path: '/comms/email/:id/folderType/',
        // path: '/comms/email/:id/emailsByfolder/',
        name: 'emailFolder',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['COMMUNICATIONS/EMAIL'],
        },
        component: () => import('../views/pages/email/folderType'),
        // component: () => import('../views/pages/email/emailsByfolder'),
    },
    {
        path: '/comms/email/:id/emailsByLabel/',
        name: 'emailLabel',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['COMMUNICATIONS/EMAIL'],
        },
        component: () => import('../views/pages/email/emailsByLabel'),
    },
    {
        path: '/comms/email/reademail/:id',
        name: 'emailmessage',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['COMMUNICATIONS/EMAIL'],
        },
        component: () => import('../views/pages/email/reademail.vue'),
    },
    {
        path: '/comms/scheduled',
        name: 'scheduled-list',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['COMMUNICATIONS/SCHEDULED'],
        },
        component: () => import('../views/pages/comms/scheduled'),
    },
    {
        path: '/comms/scheduled/create',
        name: 'scheduled-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['COMMUNICATIONS/SCHEDULED/CREATE'],
        },
        component: () => import('../views/pages/comms/scheduled/create'),
    },
    {
        path: '/comms/scheduled/:id/edit',
        name: 'scheduled-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['COMMUNICATIONS/SCHEDULED/EDIT'],
        },
        component: () => import('../views/pages/comms/scheduled/edit'),
    },
    {
        path: '/comms/chat',
        name: 'chat',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['COMMUNICATIONS/CHAT'],
        },
        component: () => import('../views/pages/chat'),
    },
    {
        path: '/comms/sms',
        name: 'sms',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['COMMUNICATIONS/SMS'],
        },
        component: () => import('../views/pages/comms/sms'),
    },
    {
        path: '/comms/sms/:id/edit',
        name: 'sms-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['COMMUNICATIONS/SMS'],
        },
        component: () => import('../views/pages/comms/sms/edit'),
    },
    {
        path: '/comms/sms/:id',
        name: 'sms-details',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['COMMUNICATIONS/SMS'],
        },
        component: () => import('../views/pages/comms/sms/details'),
    },
    {
        path: '/sales/leads',
        name: 'leads',
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            allowedPermissions: ['SALES/LEADS'],
        },
        component: () => import('../views/pages/sales/leads/leads-list'),
    },
    {
        path: '/products',
        name: 'products',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/PRODUCTS'],
        },
        component: () => import('../views/pages/products/list'),
    },
    {
        path: '/admin/locations',
        name: 'locations',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/LOCATIONS'],
        },
        component: () => import('../views/pages/admin/locations/list'),
    },
    {
        path: '/admin/locations/create',
        name: 'locations-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/LOCATIONS/CREATE'],
        },
        component: () => import('../views/pages/admin/locations/create'),
    },
    {
        path: '/admin/locations/edit/:id',
        name: 'locations-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/LOCATIONS/EDIT'],
        },
        component: () => import('../views/pages/admin/locations/edit'),
    },
    {
        path: '/admin/insurance/create',
        name: 'insurance-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TENANTS/EDIT'],
        },
        component: () => import('../views/pages/admin/insurance/create-offer'),
    },
    {
        path: '/admin/insurance/view/:id',
        name: 'insurance-view',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TENANTS/EDIT'],
        },
        component: () => import('../views/pages/admin/insurance/view-offer'),
    },
    {
        path: '/admin/insurance/list',
        name: 'All Offers',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TENANTS/EDIT'],
        },
        component: () => import('../views/pages/admin/insurance/list'),
    },
    {
        path: '/products/:id/edit',
        name: 'products-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/PRODUCTS/EDIT'],
        },
        component: () => import('../views/pages/products/edit'),
    },
    {
        path: '/sales/dashboard',
        name: 'sales-dashboard',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/DASHBOARD/VIEW'],
        },
        component: () => import('../views/pages/sales/dashboard/index'),
    },
    {
        path: '/sales/leads/create',
        name: 'leads-create',
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            allowedPermissions: ['SALES/LEADS/CREATE'],
        },
        component: () => import('../views/pages/sales/leads/create'),
    },
    {
        path: '/sales/leads/edit/:id',
        name: 'leads-edit',
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            claims: {},
            allowedPermissions: ['SALES/LEADS/EDIT'],
        },
        component: () => import('../views/pages/sales/leads/edit'),
    },
    {
        path: '/sales/activities',
        name: 'activity',
        meta: {
            authRequired: true,
            layout: main,
            offline: true,
            allowedPermissions: ['SALES/ACTIVITIES'],
        },
        component: () => import('../views/pages/sales/activities/index'),
    },
    {
        path: '/sales/activities/agents',
        name: 'agentActivity',
        meta: {
            authRequired: true,
            layout: main,
            offline: false,
            allowedPermissions: ['SALES/ACTIVITIES'],
        },
        component: () => import('../views/pages/sales/activities/agents/agent-activity'),
    },
    {
        path: '/sales/agents/actions',
        name: 'agent-actions',
        meta: {
            authRequired: true,
            layout: main,
            offline: false,
            allowedPermissions: ['SALES/AGENTS/HISTORY'],
        },
        component: () => import('../views/pages/sales/activities/agents/agentActions'),
    },

    {
        path: '/sales/agents/location/:lng/:lat',
        name: 'agent-location',
        meta: {
            authRequired: true,
            layout: tracking,
            allowedPermissions: ['SALES/AGENT/POSITION'],
        },
        component: () => import('../views/pages/sales/activities/agents/location'),
    },
    {
        path: '/sales/calculators/cs-affordability',
        name: 'cs-affordability',
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            allowedPermissions: ['SALES/AFFORDABILITY/CS'],
        },
        component: () => import('../views/pages/sales/calculators/cs-affordability'),
    },
    {
        path: '/sales/calculators/lbf-affordability',
        name: 'lbf-affordability',
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            allowedPermissions: ['SALES/AFFORDABILITY/LBF'],
        },
        component: () => import('../views/pages/sales/calculators/lbf-affordability'),
    },
    {
        path: '/sales/calculators/sme-affordability',
        name: 'sme-affordability',
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            allowedPermissions: ['SALES/AFFORDABILITY/SME'],
        },
        component: () => import('../views/pages/sales/calculators/sme-affordability'),
    },
    {
        path: '/sales/calculators/lbf-premium',
        name: 'lbf-premium',
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            allowedPermissions: ['SALES/AFFORDABILITY/LBF'],
        },
        component: () => import('../views/pages/sales/calculators/lbf-premium'),
    },
    {
        path: '/admin/tenants/products/edit',
        name: 'tenant-products',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/PRODUCTS'],
        },
        component: () => import('../views/pages/admin/tenants/tenants-products'),
    },
    {
        path: '/admin/tenants/list',
        name: 'tenants-lists',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TENANTS'],
        },
        component: () => import('../views/pages/admin/tenants/tenants-list'),
    },
    {
        path: '/admin/tenants/create',
        name: 'tenants-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TENANTS/CREATE'],
        },
        component: () => import('../views/pages/admin/tenants/tenants-create'),
    },
    {
        path: '/admin/tenants/edit/:id',
        name: 'tenants-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TENANTS/EDIT'],
        },
        component: () => import('../views/pages/admin/tenants/tenants-edit'),
    },
    {
        path: '/sales/activities/create',
        name: 'activity-create',
        meta: {
            authRequired: true,
            layout: main,
            offline: true,
            allowedPermissions: ['SALES/ACTIVITIES/CREATE'],
        },
        component: () => import('../views/pages/sales/activities/create'),
    },
    {
        path: '/sales/activities/:id/edit',
        name: 'activity-edit',
        meta: {
            authRequired: true,
            layout: main,
            offline: true,
            allowedPermissions: ['SALES/ACTIVITIES/EDIT'],
        },
        component: () => import('../views/pages/sales/activities/edit'),
    },
    {
        path: '/sales/activities/calendar',
        name: 'calendar',
        meta: {
            authRequired: true,
            layout: main,
            offline: true,
            allowedPermissions: ['SALES/ACTIVITIES/CALENDAR'],
        },
        component: () => import('../views/pages/sales/activities/calendar/index'),
    },
    {
        path: '/sales/activities/activity-plan',
        name: 'activity-plan-listing',
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            allowedPermissions: ['SALES/ACTIVITIES/ACTIVITY_PLANS'],
        },
        component: () => import('../views/pages/sales/activities/activity-plan/index'),
    },
    {
        path: '/sales/activities/activity-plan/create',
        name: 'activity-plan-create',
        props: true,
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/ACTIVITIES/ACTIVITY_PLANS/CREATE'],
        },
        component: () => import('../views/pages/sales/activities/activity-plan/create'),
    },
    {
        path: '/agents/dashboard',
        name: 'agent-dashboard',
        props: true,
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            allowedPermissions: ['SALES/AGENTS/MYWORK'],
        },
        component: () => import('../views/pages/agents/agent-dashboard'),
    },
    {
        path: '/sales/leadsdashboard',
        name: 'leads-view',
        props: true,
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'SALES', 'SALES/LEADS'],
        },
        component: () => import('../views/pages/sales/dashboard/leads-view'),
    },
    {
        path: '/sales/activities/activity-plan/:id/edit',
        name: 'activity-plan-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/ACTIVITIES/ACTIVITY_PLANS/EDIT'],
        },
        component: () => import('../views/pages/sales/activities/activity-plan/edit'),
    },
    {
        path: '/sales/activities/:id/view',
        name: 'activity-view',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/ACTIVITIES/ACTIVITY_PLANS', 'SALES/ACTIVITIES/ACTIVITIES'],
        },
        component: () => import('../views/pages/sales/activities/activity-view'),
    },
    {
        path: '/sales/activities/activity-plan/:id/map-view',
        name: 'activity-plan-map-view',
        meta: {
            authRequired: true,
            layout: tracking,
            allowedPermissions: ['SALES/ACTIVITIES', 'SALES/ACTIVITIES/ACTIVITY_PLANS'],
        },
        component: () => import('../views/pages/sales/activities/activity-plan/map-view'),
    },

    {
        path: '/sales/activities/activity-plan/map-overview',
        name: 'activity-plan-map-view-agents',
        meta: {
            authRequired: true,
            layout: tracking,
            allowedPermissions: ['SALES/ACTIVITIES', 'SALES/ACTIVITIES/ACTIVITY_PLANS'],
        },
        component: () => import('../views/pages/sales/activities/activity-plan/map-view'),
    },
    {
        path: '/sales/activities/activity-plan/day-overview',
        name: 'activity-plan-map-view-details',
        meta: {
            authRequired: true,
            layout: tracking,
            allowedPermissions: ['SALES/ACTIVITIES', 'SALES/ACTIVITIES/ACTIVITY_PLANS'],
        },
        component: () => import('../views/pages/sales/activities/activity-plan/day-overview.vue'),
    },
    {
        path: '/disbursements',
        name: 'loan-disbursements',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['LOAN/DISBURSEMENTS'],
        },
        component: () => import('../views/pages/agents/disbursements'),
    },

    {
        path: '/api-user/add',
        name: 'Add API User',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/API/CREATE'],
        },
        component: () => import('../views/pages/admin/api-user/add-api-user'),
    },
    {
        path: '/sales/teams',
        name: 'teams',
        meta: {
            authRequired: true,
            offline: true,
            layout: main,
            allowedPermissions: ['SALES/TEAMS'],
        },
        component: () => import('../views/pages/sales/teams/list'),
    },
    {
        path: '/sales/teams/add',
        name: 'add-sales-team',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/TEAMS/CREATE'],
        },
        component: () => import('../views/pages/sales/teams/create'),
    },
    {
        path: '/sales/teams/edit/:id',
        name: 'sales-team-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/TEAMS/EDIT'],
        },
        component: () => import('../views/pages/sales/teams/edit'),
    },

    {
        path: '/sales/cases',
        name: 'cases',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/CASES'],
        },
        component: () => import('../views/pages/sales/cases/list'),
    },
    {
        path: '/sales/cases/add',
        name: 'add-sales-cases',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/CASES/CREATE'],
        },
        component: () => import('../views/pages/sales/cases/create'),
    },
    {
        path: '/sales/cases/edit/:id',
        name: 'sales-cases-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/CASES/EDIT'],
        },
        component: () => import('../views/pages/sales/cases/edit'),
    },
    {
        path: '/admin/api-users/add',
        name: 'add-api-user',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/API/CREATE'],
        },
        component: () => import('../views/pages/admin/api-user/add-api-user'),
    },
    {
        path: '/admin/api-users/list',
        name: 'list-api-user',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/API/EDIT'],
        },
        component: () => import('../views/pages/admin/api-user/list'),
    },
    {
        path: '/admin/api-users/grid',
        name: 'grid-api-user',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/API'],
        },
        component: () => import('../views/pages/admin/api-user/grid'),
    },
    {
        path: '/admin/users/list',
        name: 'userslist',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/USERS'],
        },
        component: () => import('../views/pages/admin/users/list'),
    },
    {
        path: '/admin/users/list-deleted',
        name: 'userslistdeleted',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/USERS/DELETE'],
        },
        component: () => import('../views/pages/admin/users/list-deleted'),
    },
    {
        path: '/admin/users/grid',
        name: 'usersgrid',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/USERS'],
        },
        component: () => import('../views/pages/admin/users/grid'),
    },
    {
        path: '/admin/users/add',
        name: 'users-add',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/USERS/CREATE'],
        },
        component: () => import('../views/pages/admin/users/user-add'),
    },
    {
        path: '/admin/users/edit/:id',
        name: 'users-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/USERS/EDIT'],
        },
        component: () => import('../views/pages/admin/users/user-edit'),
    },
    {
        path: '/admin/roles/list',
        name: 'list roles',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/ROLES'],
        },
        component: () => import('../views/pages/admin/role/role-list'),
    },
    {
        path: '/admin/roles/add',
        name: 'add roles',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/ROLES/CREATE'],
        },
        component: () => import('../views/pages/admin/role/role-add'),
    },
    {
        path: '/admin/roles/edit/:id',
        name: 'edit role',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/ROLES/EDIT'],
        },
        component: () => import('../views/pages/admin/role/role-edit'),
    },
    {
        path: '/admin/data-models/list',
        name: 'data-models',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/DATA_MODELS'],
        },
        component: () => import('../views/pages/data-model/list'),
    },
    {
        path: '/admin/data-models/create',
        name: 'data-models-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/DATA_MODELS/CREATE'],
        },
        component: () => import('../views/pages/data-model/create'),
    },
    {
        path: '/admin/data-models/edit/:id',
        name: 'data-models-edit',
        meta: {
            authRequired: true,
            claims: {},
            allowedPermissions: ['ADMIN/DATA_MODELS/EDIT'],
        },
        component: () => import('../views/pages/data-model/edit'),
    },

    {
        path: '/admin/sales/pipelines/list',
        name: 'pipelines',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN', 'ADMIN/SALESPIPELINES'],
        },
        component: () => import('../views/pages/pipelines/list'),
    },
    // {
    //     path: '/admin/sales/pipelines/create',
    //     name: 'pipelines-create',
    //     meta: {
    //         authRequired: true,
    //         layout: main,
    //         allowedPermissions: ['ADMIN', 'Pipelines'],
    //     },
    //     component: () => import('../views/pages/pipelines/create'),
    // },
    // {
    //     path: '/admin/sales/pipelines/edit/:id',
    //     name: 'pipelines-edit',
    //     meta: {
    //         authRequired: true,
    //         layout: main,
    //         allowedPermissions: ['ADMIN', 'Pipelines'],
    //     },
    //     component: () => import('../views/pages/pipelines/edit'),
    // },

    {
        path: '/csv/upload/:model?',
        name: 'upload-file',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/LEADS', 'CLIENTS'],
        },
        component: () => import('../views/pages/csv-upload/index'),
    },
    {
        path: '/admin/template/list',
        name: 'template-list',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TEMPLATES'],
        },
        component: () => import('../views/pages/admin/templates/template-list'),
    },
    {
        path: '/admin/template/add',
        name: 'template-add',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TEMPLATES/CREATE'],
        },
        component: () => import('../views/pages/admin/templates/template-add'),
    },
    {
        path: '/admin/template/edit/:id',
        name: 'template-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/TEMPLATES/EDIT'],
        },
        component: () => import('../views/pages/admin/templates/template-edit'),
    },

    {
        path: '/admin/web-form/list',
        name: 'web-form-list',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/WEBFORMS'],
        },
        component: () => import('../views/pages/admin/web-forms/list'),
    },
    {
        path: '/admin/web-form/create',
        name: 'web-form-add',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/WEBFORMS/CREATE'],
        },
        component: () => import('../views/pages/admin/web-forms/create'),
    },
    {
        path: '/admin/web-form/edit/:id',
        name: 'web-form-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/WEBFORMS/EDIT'],
        },
        component: () => import('../views/pages/admin/web-forms/edit'),
    },
    {
        path: '/saml',
        name: 'saml',
        component: () => import('../views/pages/saml/index'),
    },
    {
        path: '/not-authorized',
        name: 'not-authorized',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/utility/not-authorized'),
    },
    {
        path: '/scheduledReport/index',
        name: 'schedule-report-index',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS'],
        },
        component: () => import('../views/pages/reports/scheduleReport/index'),
    },
    {
        path: '/reports/schedulereport/create',
        name: 'reports-schedule-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS'],
        },
        component: () => import('../views/pages/reports/scheduleReport/create'),
    },
    {
        path: '/reports/schedulereport/edit',
        name: 'reports-schedule-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS'],
        },
        component: () => import('../views/pages/reports/scheduleReport/edit'),
    },
    {
        path: '/reports',
        name: 'reports',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS'],
        },
        component: () => import('../views/pages/reports/custom'),
    }, 
    {
        path: '/reports/standard/userLoginReport',
        name: 'reports-standard-user-login',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS'],
        },
        component: () => import('../views/pages/reports/standard/userLoginReport/index'),
    },    
    {
        path: '/reports/standard/salesAgentReport',
        name: 'reports-standard-sales-agent',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS'],
        },
        component: () => import('../views/pages/reports/standard/salesAgentReport/index'),
    },   
    {
        path: '/reports/standard/parVarThirtyReport' ,
        name: 'reports-standard-parVarThirty-report',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS'],
        },
        component: () => import('../views/pages/reports/standard/parVarThirtyReport/index'),
    }, 
    {
        path: '/reports/standard/tenantLoansReport' ,
        name: 'reports-standard-tenantLoans-report',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS'],
        },
        component: () => import('../views/pages/reports/standard/tenantLoansReport/index'),
    },     
    {
        path: '/reports/standard/collectionsSummaryReport',
        name: 'reports-standard-collections-summary',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS'],
        },
        component: () => import('../views/pages/reports/standard/collectionsSummaryReport/index'),
    }, 
    {
        path: '/reports/standard',
        name: 'reports-standard',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS'],
        },
        component: () => import('../views/pages/reports/standard/index'),
    },
    {
        path: '/reports/create',
        name: 'reports-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS/CREATE'],
        },
        component: () => import('../views/pages/reports/custom/create'),
    },
    {
        path: '/reports/:id/edit',
        name: 'reports-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTS/EDIT'],
        },
        component: () => import('../views/pages/reports/custom/edit'),
    },
    {
        path: '/reports/categories',
        name: 'report-categories',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTCATEGORIES'],
        },
        component: () => import('../views/pages/reports/categories'),
    },
    {
        path: '/reports/categories/create',
        name: 'reports-categories-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTCATEGORIES/CREATE'],
        },
        component: () => import('../views/pages/reports/categories/create'),
    },
    {
        path: '/reports/categories/:id/edit',
        name: 'reports-categories-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/REPORTCATEGORIES/EDIT'],
        },
        component: () => import('../views/pages/reports/categories/edit'),
    },
    {
        path: '/sales/targets',
        name: 'sales-targets',
        meta: {
            authRequired: true,
            layout: main,
            offline: true,
            allowedPermissions: ['ADMIN', 'SALES/TARGETS'],
        },
        component: () => import('../views/pages/sales/targets/list'),
    },
    {
        path: '/sales/targets/create',
        name: 'sales-targets-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/TARGETS/CREATE'],
        },
        component: () => import('../views/pages/sales/targets/create'),
    },
    {
        path: '/sales/targets/:id/edit',
        name: 'sales-targets-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['SALES/TARGETS/EDIT'],
        },
        component: () => import('../views/pages/sales/targets/edit'),
    },
    {
        path: '/admin/business-processes/create',
        name: 'business-processes-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/BUSINESS_PROCESSES/CREATE'],
        },
        component: () => import('../views/pages/admin/business-processes/create'),
    },
    {
        path: '/admin/business-processes/:id/edit',
        name: 'business-processes-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/BUSINESS_PROCESSES/EDIT'],
        },
        component: () => import('../views/pages/admin/business-processes/edit'),
    },
    {
        path: '/admin/business-processes/list',
        name: 'business-processes-list',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/BUSINESS_PROCESSES'],
        },
        component: () => import('../views/pages/admin/business-processes/list'),
    },
    {
        path: '/admin/ip-addresses/create',
        name: 'ip-addresses-create',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/IPADDRESSES/CREATE'],
        },
        component: () => import('../views/pages/admin/ip-addresses/create'),
    },
    {
        path: '/admin/ip-addresses/:id/edit',
        name: 'ip-addresses-edit',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/IPADDRESSES/EDIT'],
        },
        component: () => import('../views/pages/admin/ip-addresses/edit'),
    },
    {
        path: '/admin/ip-addresses/list',
        name: 'ip-addresses-list',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/IPADDRESSES'],
        },
        component: () => import('../views/pages/admin/ip-addresses/list'),
    },
    {
        path: '/admin/business-process/:id/model',
        name: 'business-processes-model',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/BUSINESS_PROCESSES/EDIT'],
        },
        component: () => import('../views/pages/admin/business-processes/model'),
    },
    {
        path: '/admin/settings',
        name: 'settings',
        meta: {
            authRequired: true,
            layout: main,
            allowedPermissions: ['ADMIN/SETTINGS'],
        },
        component: () => import('../views/pages/admin/settings/list'),
    },
    {
        path: '/account/verification/:userId/:token',
        name: 'account-verification',
        component: () => import('../views/pages/account/verification'),
    },
    // final catch-all for 404 errors
    {
        path: '/coming-soon',
        name: 'coming-soon',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/utility/comingsoon'),
    },
    {
        path: '/offline',
        name: 'offline',
        meta: {
            layout: main,
            offline: true,
            authRequired: false,
        },
        component: () => import('../views/pages/utility/offline'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/utility/404'),
    },


];
