/* eslint-disable no-undef */
import axios from '../helpers/api';
import db from '@/services/pouchDb';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import helper from '../helpers/custom';
import { DateTime } from 'luxon';

export const emailService = {
    getEmailsByUserId,
    getEmail,
    sendEmail,
    getAttachment,
    getEmailFolders,
    getReadEmails,
    getUnreadEmails,
    getStarredEmails,
    createEmailFolder,
    getEmailLabels,
    createEmailLabels,
    getEmailsBylabel,
    downloadAttachment,
    updateEmailFolder,
    assignEmailToLabels,
    deleteLabels,
    removeLabelsFromEmails,
    removeAllLabelsFromEmails,
    markAsImportantOrNot,
    SoftdeleteEmails,
    insertEmail,
    insertAttachment,
    insertEmailAttachment,
    removeEmailAttachment,
    getMailbox,
    createEmail,
    updateEmail,
    insertRelatedEmail,
    getRelated,
    markRead,
    markUnread,
    markStarred,
    markUnstarred,
};

async function getEmailsByUserId(folder, id) {
    // console.log("Called getEmailsByUserId", folder, id);
    //fetch Inbox Listing Details only...
    //Trigger Background Service to load email content into PouchDB where emails are not pre-existing..
    //Add a "resync" with Server Button
    //Emails should b pre-sync'd with the users Pouch/Couch DB and loaded from there in the client side.
    //so they are stored locally on the device, can trigger notifications to the User in App and Are fast to load / read / reply etc...
    //All send emails should copy to a "sent" folder
    if (navigator.onLine) {
        try {
            const { data } = await axios.get(`/api/email/${folder}/${id}`);
            
            return data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.userDB = await db.refreshUserDb();
            }
            const { rows } = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            const emails = [];
            rows.forEach(row => {
                if (row.doc.email) {
                    emails.push(row.doc.email);
                }
            });
            return {
                result: {
                    success: true,
                    message: 'emails retrieved successfully',
                },
                data: {
                    emails,
                },
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to retrieve emails',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function getEmailsBylabel(labelName, userId) {
    // console.log("Called getEmailsByUserId", folder, id);
    //fetch Inbox Listing Details only...
    //Trigger Background Service to load email content into PouchDB where emails are not pre-existing..
    //Add a "resync" with Server Button
    //Emails should b pre-sync'd with the users Pouch/Couch DB and loaded from there in the client side.
    //so they are stored locally on the device, can trigger notifications to the User in App and Are fast to load / read / reply etc...
    //All send emails should copy to a "sent" folder
    // console.log("Here",axios.get(`/api/email/${folder}/${id}`));
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/emailLabel/${labelName}/${userId}`,
            );
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            if (!db.userDB || _.isEmpty(db.userDB)) {
                db.userDB = await db.refreshUserDb();
            }
            const { rows } = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            const emails = [];
            rows.forEach(row => {
                if (row.doc.email) {
                    emails.push(row.doc.email);
                }
            });
            return {
                result: {
                    success: true,
                    message: 'emails retrieved successfully',
                },
                data: {
                    emails,
                },
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to retrieve emails',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function getReadEmails(folder, id) {

    if (navigator.onLine) {
        try {
            const { data } = await axios.get(`/api/email/folder/read-filter/${folder}/${id}`);
            return data;
        } catch (error) {
            return error;
        }
    } else {
        
    }
}

async function getUnreadEmails(folder, id) {

    if (navigator.onLine) {
        try {
            const { data } = await axios.get(`/api/email/folder/unread-filter/${folder}/${id}`);
            return data;
        } catch (error) {
            return error;
        }
    } else {
        
    }
}

async function getStarredEmails(folder, id) {

    if (navigator.onLine) {
        try {
            const { data } = await axios.get(`/api/email/folder/starred-filter/${folder}/${id}`);
            return data;
        } catch (error) {
            return error;
        }
    } else {
        
    }
}

async function getEmail(id, userId) {
    if (navigator.onLine) {
        try {
            //fetch email content from PouchDB for the selected item...
            const res = await axios.get(`/api/email/single/${id}/${userId}`);
            return res.data[0];
        } catch (error) {
            return error;
        }
    } else {
        const res = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        let response = {};
        res.rows.forEach(row => {
            if (row.doc.email !== undefined && row.doc.email.Id === id) {
                response = {
                    data: { email: row.doc.email },
                    result: {
                        success: true,
                        message: 'email retrieved successfully.',
                    },
                };
            }
        });
        return response;
    }
}

async function getMailbox(userId) {
    if (navigator.onLine) {
        try {
            const res = await axios.get(`/api/email/mailbox/${userId}`);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        const res = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        let response = {};
        res.rows.forEach(row => {
            if (row.doc.mailboxId !== undefined && row.doc.email.Id === userId) {
                // chack with the guys tomorrow // @Tlangelani and Dean please confirm if correct when reviewing pr
                response = {
                    data: { email: row.doc.mailboxId },
                    result: {
                        success: true,
                        message: 'mailbox retrieved successfully.',
                    },
                };
            }
        });
        return response;
    }
}

/**
 * Send email using REST API router.
 * @param {(string|string[])} to
 * @param {string} subject
 * @param {string} message
 * @param {Object} options
 * @returns
 */
async function sendEmail(
    from,
    to,
    cc,
    bcc,
    subject,
    message,
    attachments,
    options,
) {
    // console.log("debug: getting to sendEmail");
    const payload = {
        from,
        to,
        cc,
        bcc,
        subject,
        message,
        attachments,
        options,
    };
    try {
        // Send Email via Send Email service, which will do logging to Database into Sent Folder and will then Sync with PouchDB once it's been verified as sent.
        const res = await axios.post('/api/email', payload);
        return res.data;
    } catch (error) {
        //console.log('debug: backofficeui/sendEmail error: ', error);
        return error;
    }
}

async function getAttachment(attachmentId) {
    if (navigator.onLine) {
        try {
            const res = await axios.get(
                `/api/email/attachment/${attachmentId}`,
            );
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        const res = await db.userDB.allDocs({
            include_docs: true,
            attachments: true,
        });
        let response = {};
        res.rows.forEach(row => {
            if (
                row.doc.attachment !== undefined &&
                row.doc.attachment.Id === attachmentId
            ) {
                response = {
                    data: { email: row.doc.attachmentId },
                    result: {
                        success: true,
                        message: 'email attachment retrieved successfully.',
                    },
                };
            }
        });
        return response;
    }
}

async function getEmailFolders(id) {
    if (navigator.onLine) {
        try {
            //fetch email folder content from PouchDB for the selected item...
            const res = await axios.get(`/api/emailFolders/${id}`);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let emailFolders = [];
            res.rows.forEach(row => {
                if (row.doc.emailFolder) {
                    emailFolders.push(row.doc.emailFolder);
                }
            });
            let temp = {
                data: { folders: emailFolders },
                result: {
                    success: true,
                    message: 'Email folders retrieved successfully.',
                },
            };
            return temp;
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to retrieve email folders',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function createEmailFolder(data) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    if (navigator.onLine) {
        try {
            const res = await axios.post(`/api/emailFolders/`, data);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            await db.userDB.put({
                _id: data.Id,
                from: 'offline',
                emailFolder: data,
            });
            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: data.Id,
                    userId: helper.getUserId(),
                    entityType: 'emailFolder',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'CREATE',
                },
            });
            return {
                result: {
                    success: true,
                    message: 'Email Folder created successfully',
                },
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to create email label',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function getEmailLabels(id) {
    if (navigator.onLine) {
        try {
            //fetch email folder content from PouchDB for the selected item...
            const res = await axios.get(`/api/emailLabel/${id}`);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            const res = await db.userDB.allDocs({
                include_docs: true,
                attachments: true,
            });
            let emailLabels = [];
            res.rows.forEach(row => {
                if (row.doc.emailLabel) {
                    emailLabels.push(row.doc.emailLabel);
                }
            });
           
            let temp = {
                data: { labels: emailLabels },
                result: {
                    success: true,
                    message: 'Email labels retrieved successfully.',
                },
            };
            console.log('temp', temp);
            return temp;
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to retrieve email labels',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function createEmailLabels(data) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    if (navigator.onLine) {
        try {
            const res = await axios.post(`/api/emailLabel/`, data);
            console.log('results', res.data);
            return res.data;
        } catch (error) {
            //console.log(error);
            return error;
        }
    } else {
        try {
            await db.userDB.put({
                _id: data.Id,
                from: 'offline',
                emailLabel: data,
            });

            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: data.Id,
                    userId: helper.getUserId(),
                    entityType: 'emailLabel',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'CREATE',
                },
            });
            return {
                result: {
                    success: true,
                    message: 'Email label created successfully',
                },
                data: {},
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to update email label',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function updateEmailFolder(id, userId, folder) {
    const payload = {
        emailId: id,
        userId: userId,
        folder: folder,
    };
    if (navigator.onLine) {
        try {
            const res = await axios.put(`/api/email/update/folder`, payload);
            return res.data;
        } catch (error) {
            //console.log('Error', error);
            return error;
        }
    } else {
        try {
            await db.userDB.put(
                {
                    _id: id,
                    from: 'offline',
                    folderName: folder.folderName,
                },
                { force: true },
            );
            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: id,
                    userId: helper.getUserId(),
                    entityType: 'emailFolder',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'UPDATE',
                },
            });
            return {
                result: {
                    success: true,
                    message: 'email folder updated successfully',
                },
                data: {},
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to update email folder',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function assignEmailToLabels(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    data['Ids'] = data.emailIds.map(() => uuidv4());
    try {
        //fetch email folder content from PouchDB for the selected item...
        const res = await axios.post(`/api/emailLabel/${user.Id}`, data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function deleteLabels(data) {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
        const res = await axios.post(`/api/emailLabel/edit-labels/delete-labels/${user.Id}`, data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function removeLabelsFromEmails(selectedLabels, selectedEmails) {
    const user = JSON.parse(localStorage.getItem('user'));
    let data = {
        selectedLabels: selectedLabels,
        selectedEmails: selectedEmails
    }
    try {
        const res = await axios.post(`/api/emailLabel/edit-labels/remove-labels-from-emails/${user.Id}`, data);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function removeAllLabelsFromEmails(selectedEmails) {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
        const res = await axios.post(`/api/emailLabel/edit-labels/remove-all-labels-from-emails/${user.Id}`, selectedEmails);
        return res.data;
    } catch (error) {
        return error;
    }
}

async function markAsImportantOrNot(ids) {
    const payload = {
        ids,
    };
    if (navigator.onLine) {
        try {
            //this will mark an email important or not important...
            const res = await axios.put(`/api/email/`, payload);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            const doc = await db.userDB.get(id);
            await db.userDB.put({
                _id: data.Id,
                _rev: doc._rev,
                from: 'offline',
                email: data, // Not too sure
            });
            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: data.Id,
                    entityType: 'scheduled',
                    userId: helper.getUserId(),
                    dateModified: DateTime.now().ToISO(),
                    changeType: 'update',
                },
            });
        } catch (error) {
            return error;
        }
    }
}

async function SoftdeleteEmails(ids) {
    if (navigator.onLine) {
        try {
            const res = await axios.post('/api/email/delete', { ids });
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            ids.forEach(async id => {
                // eslint-disable-next-line no-undef
                const doc = await db.userDB.get(data);
                await db.userDB.remove(doc);
                let changeId = uuidv4();
                await db.globalsDB.put({
                    _id: changeId,
                    from: 'offline',
                    change: {
                        entityId: id,
                        entityType: 'emails',
                        userId: helper.getUserId(),
                        dateModified: DateTime.now().toISO(),
                        changeType: 'delete',
                    },
                });
            });
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to delete email',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function downloadAttachment(key) {
    try {
        if (key.split('/')[0] == 'email-attachments')
            key = 'email-attachment/' + key.split('/')[1];
        const { data } = await axios.get(
            process.env.VUE_APP_UPLOADIMAGES_URL + '/' + key,
            { responseType: 'arraybuffer' },
        );
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
}

async function insertEmail(email) {
    email['Id'] = helper.checkId(email.Id) ? email.Id : uuidv4();
    if (navigator.onLine) {
        try {
            const { data } = await axios.post('/api/email/insert', email);
            return data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            await db.userDB.put({
                _id: email.Id,
                from: 'offline',
                email: email,
            });

            let email_global = uuidv4();
            await db.globalsDB.put({
                _id: email_global,
                change: {
                    entityId: email.Id,
                    userId: helper.getUserId(),
                    entityType: 'email',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'INSERT',
                },
            });
            return {
                result: {
                    success: true,
                    message: 'email inserted successfully',
                },
                data: {},
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to insert email',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function insertRelatedEmail(email) {
    try {
        const { data } = await axios.post('/api/email/insertRelated', {
            emailId: email.emailId,
            relatedId: email.relatedId,
            type: email.type,
        });
        //console.log('related Data', data);
        return data;
    } catch (error) {
        //console.log('related Error', error);
        return error;
    }
}

async function getRelated(emailId) {
    try {
        const { data } = await axios.get(`/api/email/selectRelated/${emailId}`);
        return data;
    } catch (error) {
        //console.log(error);
        return error;
    }
}

async function insertAttachment(attachment) {
    attachment['Id'] = helper.checkId(attachment.Id) ? attachment.Id : uuidv4();
    if (navigator.onLine) {
        try {
            console.debug('given attachment:', attachment);
            const { data } = await axios.post(
                '/api/email/attachment',
                attachment,
            );
            return data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            await db.userDB.put({
                _id: attachment.Id,
                from: 'offline',
                attachment: attachment,
            });

            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: attachment.Id,
                    userId: helper.getUserId(),
                    entityType: 'attachment',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'INSERT',
                },
            });
            return {
                result: {
                    success: true,
                    message: 'Successfully inserted attachment',
                },
                data: {},
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to insert attachment',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function insertEmailAttachment(email_attachments) {
    try {
        const { data } = await axios.post(
            '/api/email/email_attachment',
            email_attachments,
        );
        return data;
    } catch (error) {
        return error;
    }
}

async function removeEmailAttachment(email_attachment) {
    try {
        const { data } = await axios.post(
            '/api/email/remove/email_attachment',
            email_attachment,
        );
        return data;
    } catch (error) {
        return error;
    }
}

async function createEmail(data) {
    data['Id'] = helper.checkId(data.Id) ? data.Id : uuidv4();
    if (navigator.onLine) {
        try {
            const res = await axios.post('/api/email/create', data);
            return res.data;
        } catch (error) {
            return error;
        }
    } else {
        try {
            const email = {
                Id: data.Id,
                userId: helper.getUserId(),
                to: data.to || '',
                cc: data.cc || '',
                bcc: data.bcc || '',
                fromAddress: data.fromAddress || '',
                fromName: data.fromName || '',
                headers: data.headers || '',
                subject: data.subject || '',
                body: data.body || '',
                status: data.status || null,
                folder: data.folder || null,
            };

            await db.userDB.put({
                _id: email.Id,
                from: 'offline',
                email: email,
            });

            let email_global = uuidv4();
            await db.globalsDB.put({
                _id: email_global,
                change: {
                    entityId: email.Id,
                    userId: helper.getUserId(),
                    entityType: 'email',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'CREATE',
                },
            });
            return {
                result: {
                    success: true,
                    message: 'email created successfully',
                },
                data: {},
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to create email',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function updateEmail(id, userId, data) {
    if (navigator.onLine) {
        const res = await axios.put(`/api/email/update/${id}/${userId}`, data);
        return res.data;
    } else {
        try {
            await db.userDB.put(
                {
                    _id: id,
                    from: 'offline',
                    email: data,
                },
                { force: true },
            );
            let changeId = uuidv4();
            await db.globalsDB.put({
                _id: changeId,
                change: {
                    entityId: id,
                    userId: helper.getUserId(),
                    entityType: 'email',
                    dateModified: DateTime.now().toISO(),
                    changeType: 'UPDATE',
                },
            });
            return {
                result: {
                    success: true,
                    message: 'email updated successfully',
                },
                data: {},
            };
        } catch (error) {
            return {
                result: {
                    success: false,
                    message: 'Failed to update email',
                },
                error: {
                    message: error,
                },
            };
        }
    }
}

async function markRead(ids, userId) {
    const res = await axios.put(`/api/email/read`, { ids, userId });
    return res.data;
}
async function markUnread(ids, userId) {
    const res = await axios.put(`/api/email/unread`, { ids, userId });
    return res.data;
}

async function markStarred(ids, userId) {
    const res = await axios.put(`/api/email/star`, { ids, userId });
    return res.data;
}

async function markUnstarred(ids, userId) {
    const res = await axios.put(`/api/email/unstar`, { ids, userId });
    return res.data;
}
